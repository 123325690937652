import { Container } from "react-bootstrap";


const PhotoSection = () => {
    return (
        <section id="photos" className="photos-block photos2">
             <Container fluid>
                <div className="title-holder">
                    {/* <h2>Thank you for being part of our lifes</h2> */}
                    {/* <div className="subtitle">And your loved ones</div> */}
                </div>
             </Container>

        </section>
       

    );
}

export default PhotoSection;