import { Container } from "react-bootstrap";


const GalleryCarousel = () => {
  return (
    <section id="photos" className="photos-block photos1">
         <Container fluid>
            <div className="title-holder">
                {/* <h2>Nothing in this life is better than being with the love of your life</h2>
                <div className="subtitle">And your loved ones</div> */}
            </div>
         </Container>

    </section>
   

  );
}
export default GalleryCarousel;
