import { Container } from "react-bootstrap";


const PhotoSection2 = () => {
    return (
        <section id="photos" className="photos-block photos3">
             <Container fluid>
                <div className="title-holder">
                    {/* <h2>Nothing in this life is better than being with the love of your life</h2>
                    <div className="subtitle">And your loved ones</div> */}
                </div>
             </Container>

        </section>
       

    );
}

export default PhotoSection2;